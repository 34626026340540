// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'Login',
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_AUTH.login,
    isAuth: false,
  },
  {
    title: 'Register',
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_AUTH.register,
    isAuth: false,
  }
];

export default navConfig;
