import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack } from '@mui/material';
import { Course } from 'src/@types/courses';
import { doc, collection, addDoc, updateDoc } from "firebase/firestore";
import { db } from 'src/auth/FirebaseContext';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, {
  RHFTextField,
  RHFAutocomplete,
  RHFCheckbox,
} from '../../../../components/hook-form';



export type FormValuesProps = Course;

interface Props {
  currentCourse?: any,
  isEdit?: any,
  lessons?: any,
  exams?: any,
  licences?: any,
}

export default function CoursesNewEditForm(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const NewBlogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    icon: Yup.string().required('Icon url is required'),
    lessons: Yup.array().min(1, 'Lessons must contain at least one element').required('Lesson is required'),
    exam: Yup.object().required('Exam is required').nullable(),
    image: Yup.string().required('Image url is required'),
    licence: Yup.object().required('Licence is required').nullable(),
    isFree: Yup.boolean(),
    storeProductId: Yup.string().when('isFree', {
      is: false,
      then: Yup.string().required('StoreProductId is required.'),
    }),
  });

  const { currentCourse, isEdit, lessons, exams, licences } = props;

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewBlogSchema),
    defaultValues: isEdit ? {
      title: currentCourse?.title || '',
      description: currentCourse?.description || '',
      icon: currentCourse?.icon || '',
      lessons: currentCourse?.lessons,
      exam: currentCourse?.exam || null,
      image: currentCourse?.image || '',
      licence: currentCourse?.licence || null,
      isFree: currentCourse?.isFree || false,
      storeProductId: currentCourse?.storeProductId || ''
    } 
    : {
      // lessons:  [],
      // title: '',
      // description: '',
      // icon:  '',
      // exam:  null,
      // image: '',
      // licence: null,
      // isFree: false,
      // storeProductId: ''
    },
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;



  const onSubmit = async (data: FormValuesProps) => {
    const { description = "", title = "", icon, lessons: lessonItem, isFree = false, exam, image, licence, storeProductId: storeProductIdValue = null } = data;

    if (lessonItem.some(lesson => lesson === undefined)) {
      enqueueSnackbar('Invalid lesson selected!', { variant: "error" });
      return;
    }

    const examRef = exam?.value ? doc(db, 'exams', exam.value) : null;
    const lessonsRef = lessonItem?.map(lesson => doc(db, `lessons`, lesson.value)) || [];
    const licenceRef = licence?.value ? doc(db, 'licences', licence.value) : null;

    const storeProductId = isFree ? null : storeProductIdValue

    try {
      if (isEdit) {
        await updateDoc(doc(db, "courses", id as string), {
          description,
          title,
          icon: icon || '',
          lessons: lessonsRef,
          exam: examRef,
          image: image || '',
          licence: licenceRef,
          isFree,
          storeProductId
        });
      } else {
        await addDoc(collection(db, "courses"), {
          description,
          title,
          icon: icon || '',
          lessons: lessonsRef,
          exam: examRef,
          image: image || '',
          licence: licenceRef,
          isFree,
          storeProductId
        });
        reset();
      }

      enqueueSnackbar('Success!');
    } catch (error) {
      enqueueSnackbar('Something is wrong. Try again!', { variant: "error" });
      console.log("onSubmit error: ", error)
    }
  };

  const showExtraField = watch('isFree');

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid spacing={3} xs={12} md={6} justifyContent="center" >
        <Grid item xs={12} md={6} >
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>

              <RHFAutocomplete
                name="licence"
                label="Licence"
                options={licences}
                getOptionLabel={(option: any) => option.label}
                ChipProps={{ size: 'small' }}
              />

              <RHFTextField name="title" label="Title" value={watch('title')} />

              <RHFTextField name="description" label="Description" multiline rows={3} />

              <RHFTextField name="image" label="Image" />

              <RHFAutocomplete
                name="lessons"
                label="Lessons"
                multiple
                options={lessons}
                getOptionLabel={(option: any) => option?.label}
                ChipProps={{ size: 'small' }}
              />

              <RHFAutocomplete
                name="exam"
                label="Exam"
                options={exams}
                getOptionLabel={(option: any) => option.label}
                ChipProps={{ size: 'small' }}
              />

              <RHFTextField name="icon" label="Icon" />

              <RHFCheckbox name="isFree" label="Is Free" />

              {!showExtraField &&
                <RHFTextField name="storeProductId" label="Store product ID" />
              }

            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          size="large"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {isEdit ? 'Update' : 'Create'} & Send
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

// const { handleEditPhoto } = usePhotoUpdater(db, storage, enqueueSnackbar);

// const handleImageChange = (event) => {
//   handleEditPhoto(event, 'courses', String(id), 'image', setValue);
// };

// const handleIconChange = (event) => {
//   handleEditPhoto(event, 'courses', String(id), 'icon', setValue);
// };

// <TextField
//   type="file"
//   name="image"
//   helperText="Image"
//   inputProps={{ accept: 'image/*' }}
//   onChange={handleImageChange}
// />

// <TextField
//   type="file"
//   name="icon"
//   helperText="Icon"
//   inputProps={{ accept: 'image/*' }}
// />