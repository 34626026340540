import { useEffect, useState } from "react";
import { useAuthContext } from "src/auth/useAuthContext";

  
function useRoleBasedAccess (allowedRoles) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => {
    const userRole = user?.role;

    if (allowedRoles.includes(userRole)) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedRoles]);

  return isAuthorized;
};


export default useRoleBasedAccess