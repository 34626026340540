import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography, Link } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';
// import AuthWithSocial from './AuthWithSocial';


export default function Login() {

  return (
    <LoginLayout title='Welcome aboard the Pilot Planner' subtitle='Before we take flight, make sure you log in with the correct coordinates!'>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to dashboard</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack>

        {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email: <strong>arber@coetic-x.com</strong> / password:<strong> Shabi123</strong>
      </Alert> */}

      <AuthLoginForm />

      {/* <AuthWithSocial /> */}
    </LoginLayout>
  );
}
