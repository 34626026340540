import {WithFieldValue } from "firebase/firestore";


const postConverter: any = {
    toFirestore(post: WithFieldValue<any>): any {
      return { ...post };
    },
    fromFirestore(
      snapshot: any,
      options: any
    ): any {
      const data = snapshot.data(options);
      return {
        ...data,
        id: snapshot.id,
      };
    },
  };

export const withId = (fn: {withConverter: Function}) => fn.withConverter(postConverter);
  