// @mui
import { Stack } from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
//
import { NavProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavDesktop({ isOffset, data }: NavProps) {
  const {isAuthenticated} = useAuthContext();

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 5, height: 1 }}>
      {data.filter(link=> !link?.isAuth && !isAuthenticated).map((link) => (
        <NavList key={link.title} item={link} isOffset={isOffset} />
      ))}
    </Stack>
  );
}
