import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';
import { collection, getDocs, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from 'src/auth/FirebaseContext';
import QuestionsTableRow from 'src/sections/@dashboard/questions/list/QuestionsTableRow';
import { Questions } from 'src/@types/question';
import { chain } from 'lodash';
import { PATH_DASHBOARD } from '../../routes/paths';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import ConfirmDialog from '../../components/confirm-dialog';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../components/table';


const TABLE_HEAD = [
  { id: 'question', label: 'question', align: 'left' },
  { id: 'answers', label: 'answers', align: 'left' },
  { id: 'isFree', label: 'isFree', align: 'left' },
  { id: 'more', label: '', align: 'left' },
];


export default function QuestionsListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<Questions[]>([]);
  const [tabs, setTabs] = useState<string[]>([]);
  const [filterName] = useState('');
  const [filterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');

  const fetchQuestions = async () => {
    try {
      const questions = await getDocs(collection(db, 'questions'));

      const questionsData = await Promise.all(questions.docs.map(async examItem => {
        const examData = examItem.data();

        try {
          const course = (await getDoc(examData.course)).data() || {};
          const exam = (await getDoc(examData.exam)).data() || {};

          examData.course = { ...course, id: examData.course.id };
          examData.exam = { ...exam, id: examData.exam.id };
        } catch (error) {
          console.error(error);
        }

        return {
          ...examData,
          id: examItem.id,
        }
      }))

      const res = chain(questionsData)
        .groupBy("exam.title")
        .map((value, key) => (key))
        .value()
      console.log(res);

      const filteredTabs = res.filter(value => value !== 'undefined');
      const deletedTabs = res.filter(value => value === 'undefined');

      // @ts-ignore      
      setTabs([...filteredTabs, ...deletedTabs]);

      handleFilterStatus(null, res[0])
      // @ts-ignore
      setTableData(questionsData)

    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataFiltered = applyFilter({
    inputData: tableData as any,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });
  console.log(tableData);

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterStatus = (event: any, newValue: string) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await deleteDoc(doc(db, "questions", id));

      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);

      const res = chain(deleteRow)
      .groupBy("exam.title")
      .map((value, key) => (key))
      .value()
 
      setTabs(res);
      handleFilterStatus(null, res[0])

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
    } catch (error) {
      console.log("error handleDeleteRow: ", error)
    }
  };

  const handleDeleteRows = (selectedRows: string[]) => {
    const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === dataFiltered.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }
  };

  const handleEditRow = async (id: string) => {
    console.log("here", id)
    navigate(PATH_DASHBOARD.questions.edit(id));
  };

  return (
    <>
      <Helmet>
        <title> Questions: List | Pilot Planner</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Questions List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Questions', href: PATH_DASHBOARD.questions.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.questions.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Question
            </Button>
          }
        />

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab} label={tab === 'undefined' ? "Deleted Exam" : tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={() => { }}
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>

                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <QuestionsTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onViewRow={() => { }}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: Questions[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (item) => item
    );
  }

  if (filterStatus === 'undefined') {
    inputData = inputData.filter((user: any) => user.exam.title === undefined);
  } else {
    inputData = inputData.filter((user: any) => user.exam.title === filterStatus)
  }

  return inputData;
}
