import React, { useState } from 'react';
import {
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  ListItemAvatar,
} from '@mui/material';
import { Course } from 'src/@types/courses';
import useRoleBasedAccess from 'src/hooks/useRoleBasedAccess';
import Label, { LabelColor } from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';



type Props = {
  row: Course;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CourseTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: Props) {

  const { image, title, description, lessons, exam } = row;

  const isAuthorized = useRoleBasedAccess(['admin']);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disabled={!isAuthorized} checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <div>
              <Typography variant="subtitle2" noWrap>
                {title}
              </Typography>
            </div>
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div>
              <Typography variant="subtitle2">
                {description.slice(0, 100)}{description.length > 160 ? '...' : ''}
              </Typography>
            </div>
          </Stack>
        </TableCell>

        <TableCell align="center">{
          lessons.map((lesson, i) => (
            lesson.title &&
            <React.Fragment key={i}>
              <Label
                variant="soft"
                color={['info', 'primary'][i % 2 === 0 ? 0 : 1] as LabelColor || "info"}
                sx={{ textTransform: 'capitalize', marginBottom: 1 }}
              >
                {lesson.title}
              </Label><br />
            </React.Fragment>
          ))}
        </TableCell>

        <TableCell align="center">
          {exam?.id && (
            <Typography key={exam?.id} variant="body2" sx={{ cursor: 'pointer' }}>
              <Label
                variant="soft"
                color={exam?.title ? "info" : "error"}
                sx={{ textTransform: 'capitalize', marginBottom: 1 }}
              >{exam?.title ? exam?.title : 'Unavailable exam'}</Label>
            </Typography>
          )}
        </TableCell>

        <TableCell padding="checkbox">
          <ListItemAvatar>
            {image ?
              <img src={image} alt="Not found" />
              :
              <Label
                variant="soft"
                color="error"
                sx={{ textTransform: 'capitalize', marginBottom: 1 }}>
                Not found
              </Label>
            }
          </ListItemAvatar>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

        {isAuthorized &&
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="eva:trash-2-outline" />
              Delete
            </MenuItem>
          </>
        }

      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
