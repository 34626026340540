// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [

      // LICENSE CATEGORIES
      {
        title: 'licences',
        path: PATH_DASHBOARD.licences.root,
        icon: <Iconify icon="mdi:drivers-license"/>,
      },

      // Courses
      {
        title: 'Courses',
        path: PATH_DASHBOARD.courses.root,
        icon: <Iconify icon="fluent-mdl2:publish-course"/>,
      },

      // CATEGORY
      {
        title: 'category',
        path: PATH_DASHBOARD.category.root,
        icon: <Iconify icon="uim:layer-group"/>,
      },

      // LESSONS
      {
        title: 'Lessons',
        path: PATH_DASHBOARD.lessons.root,
        icon: <Iconify icon="mdi:book-open-variant"/>,
      },

      // Questions
      {
        title: 'Questions',
        path: PATH_DASHBOARD.questions.root,
        icon: <Iconify icon="bx:spreadsheet"/>,
      },

      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.account,
        icon: <Iconify icon="mdi:user-supervisor" />,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account },
          { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
        ],
      },

      // MEDICAL LICENSE CATEGORIES
      {
        title: 'Medical Licences',
        path: PATH_DASHBOARD.medicalLicences.root,
        icon: <Iconify icon="material-symbols:medical-information-outline-sharp"/>,
      },

      // Exams
      {
        title: 'Exams',
        path: PATH_DASHBOARD.exams.root,
        icon: <Iconify icon="healthicons:i-exam-multiple-choice-outline"/>,
      },
    ],
  },
];

export default navConfig;
