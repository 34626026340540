import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { collection, doc, query } from 'firebase/firestore';
import { db } from 'src/auth/FirebaseContext';
import CourseNewEditForm from 'src/sections/@dashboard/courses/form/CourseNewEditForm';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { withId } from 'src/firestore/withId';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import { PATH_DASHBOARD } from '../../routes/paths';


export default function CourseEditPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();
  const [course, loading] = useDocumentData(withId(
    doc(db, `courses/${id}`)
  ));


  const [licences] = useCollectionData(withId(
    collection(db, 'licences')
  ));

  const licence = licences?.find(item => item.id === course?.licence?.id);
  const licenceOptions = licences?.map(item => ({ value: item?.id, label: item?.title }));


  const [lessons = [], loadingLessons] = useCollectionData(withId(query(
    collection(db, 'lessons'),
  )));
  const lessonsOptions = lessons?.map(lesson => ({ label: lesson.title, value: lesson.id }))


  const [exams = [], loadingExams] = useCollectionData(withId(query(
    collection(db, 'exams'),
  )));

  const exam = exams?.find(item => item.id === course?.exam?.id);
  const examsOptions = exams.map(item => ({ label: item.title, value: item.id }))

  const courseWithExamAndLessons = {
    ...course,
    exam: exam?.id ? { value: exam?.id, label: exam?.title } : null,
    lessons: course?.lessons.map(courseLesson => lessonsOptions?.find(lesson => courseLesson.id === lesson.value) ),
    licence: licence?.id ? { value: licence?.id, label: licence?.title } : null,
  }


  if (loading || loadingLessons || loadingExams) return null;
  return (
    <>
      <Helmet>
        <title>Course: Edit course | Pilot Planner</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit course"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Courses',
              href: PATH_DASHBOARD.courses.list,
            },
            { name: course?.title },
          ]}
        />

        {course && <CourseNewEditForm isEdit currentCourse={courseWithExamAndLessons} lessons={lessonsOptions} exams={examsOptions} licences={licenceOptions} />}
      </Container>
    </>
  );
}
