import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}



const SvgComponent = (props: any) => {
  const {sx, primarymain, secondary} = props
  
  return (
    <div style={{display:"flex", justifyContent:"center", margin: sx?.mx ?'10px 0 2px':'10px 0'}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={sx?.mx ?55:94}
      height={sx?.mx ? 57:97.1475}
  
      viewBox="0 0 188 194.295"
      {...props}
    >
      <g data-name="LOGO">
        <path
          data-name="Path 3"
          d="M184.972 62.826a38.987 38.987 0 0 1-38.943 38.943h-22.807a17.842 17.842 0 0 0-17.819 17.827v11.044a25.575 25.575 0 0 1-25.545 25.552h-7.691v-53.396q0-.492.021-.985v-4.275h17a51.612 51.612 0 0 0 42.359-22.2 50.73 50.73 0 0 0 2.04-3.185h12.33c7.335 0 13.279-4.177 13.279-9.339s-5.944-9.339-13.279-9.339h-5.721a50.5 50.5 0 0 0 .552-7.5 51.54 51.54 0 0 0-4.987-22.1h10.268a38.993 38.993 0 0 1 38.943 38.95"
          fill={primarymain}
        />
        <path
          data-name="Path 4"
          d="M135.16 45.977a46.016 46.016 0 0 1-45.97 45.97H62.269a21.062 21.062 0 0 0-21.04 21.04v13.041a30.19 30.19 0 0 1-30.155 30.162H2V93.162C2 72.772 21.691 54.9 44.135 54.9h6.936l-4.442 14.191h5.12a11.624 11.624 0 0 0 8.871-4.114l8.878-10.5.063-.07c.873.049 1.753.084 2.626.112 1.041.028 2.082.042 3.108.042 1.586 0 3.136-.035 4.589-.1 10.869-.51 23.561-3 23.561-8.389s-12.692-7.886-23.561-8.389c-1.453-.07-3-.1-4.6-.1q-1.54 0-3.1.042c-.831.028-1.662.063-2.487.1L60.62 26.97a11.586 11.586 0 0 0-8.871-4.107h-5.12l4.443 14.194h-6.937C22.138 37.057 2.782 19.88 2.021 0H89.19a46.005 46.005 0 0 1 45.97 45.977"
          fill={secondary}
        />
      </g>
      {!sx?.mx && (
      <text
        data-name="PILOT PLANNER"
        transform="translate(0 189.295)"
        fill={primarymain}
        fontSize={19}
        fontFamily="Montserrat-Medium, Montserrat"
        letterSpacing=".129em"
        fontWeight={500}
      >
        <tspan x={0} y={0}>
          PILOT
        </tspan>
        <tspan
          y={0}
          xmlSpace="preserve"
          fill={secondary}
          fontFamily="Montserrat-Regular, Montserrat"
          fontWeight={400}
        >
          {" PLANNER"}
        </tspan>
      </text>
      )}
    </svg>
    </div>

  )

  }




const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const isLight = theme.palette.mode === 'light';
    // const primarylight = ;

    const primarymain = theme.palette.primary.main;

    const secondary = isLight ? theme.palette.primary.dark: theme.palette.primary.light;

    // const primarylight="#fff";
    // const primarymain = "#23cb7d";
    // const primarydark = "#283071";

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );


    if (disabledLink) {
      return <SvgComponent primarymain={primarymain} secondary={secondary} sx={sx}/>;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        <SvgComponent primarymain={primarymain} secondary={secondary} sx={sx}/>
      </Link>
    );
  }
);

export default Logo;
