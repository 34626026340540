import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from '@mui/material';
import { collection, getDocs, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from 'src/auth/FirebaseContext';
import CourseTableRow from 'src/sections/@dashboard/courses/list/CourseTableRow';
import { Course } from 'src/@types/courses';
import { PATH_DASHBOARD } from '../../routes/paths';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import ConfirmDialog from '../../components/confirm-dialog';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../components/table';


const TABLE_HEAD = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'lessons', label: 'Lessons', align: 'center' },
  { id: 'exam', label: 'Exam', align: 'center' },
  { id: 'image', label: 'Image', align: 'left' },
  { id: 'more', label: '', align: 'left' },
];


export default function CourseListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<any[]>([]);
  const [filterName] = useState('');
  const [filterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus] = useState('all');

  const fetchCourses = async () => {
    try {
      const courses = await getDocs(collection(db, 'courses'));
      const coursesWithLessons = await Promise.all(courses.docs.map(async course => {
        const courseData = course.data();

        try{
          const lessons = await Promise.all(courseData.lessons.map(async (lesson) => {
            const lessonData = (await getDoc(lesson)).data() || {};

            return {
              ...lessonData,
              id: lesson.id
            };
          }));
          
          const examData = (await getDoc(courseData.exam)).data() || {};
          courseData.lessons = lessons;
          courseData.exam = {...examData, id:courseData.exam.id};
        }catch(error) {
          console.error(error);
        }
        
        return {
          ...courseData,
          id: course.id,
        }
      }))

      setTableData(coursesWithLessons)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(()=> {
    fetchCourses()
  },[])

  const dataFiltered = applyFilter({
    inputData: tableData as any,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await deleteDoc(doc(db, "courses", id));
      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);
  
      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
    } catch (error) {
      console.log("error handleDeleteRow: ", error)
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    let err = false;

    selectedRows.forEach(async(id)=>{
      try {
        await deleteDoc(doc(db, "courses", id));
        if (page > 0) {
          if (dataInPage.length < 2) {
            setPage(page - 1);
          }
        }
      } catch (error) {
        err = true;
        console.log("error handleDeleteRows: ", error)
      } finally {
        if (err) {
          console.log('error', err)
          // return
        }
        const deleteRow = tableData.filter((row) => !selectedRows.includes(row.id));
        setSelected([]);
        setTableData(deleteRow);
      }
    }
  )};

  const handleEditRow = async (id: string) => {
    console.log("here",id)
    navigate(PATH_DASHBOARD.courses.edit(id));
  };

  return (
    <>
      <Helmet>
        <title> Courses: List | Pilot Planner</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Courses List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Courses', href: PATH_DASHBOARD.courses.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.courses.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Course
            </Button>
          }
        />

        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <CourseTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onViewRow={() => {}}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
}: {
  inputData: Course[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (item) => item.title.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}
