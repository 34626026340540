import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { PATH_DASHBOARD } from '../../routes/paths';
import { _categorys } from '../../_mock/arrays';
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';


export default function CourseDetailsPage() {
  const { themeStretch } = useSettingsContext();
  const { id } = useParams();
  const currentInvoice = _categorys.find((category) => category.id === id);

  return (
    <>
      <Helmet>
        <title> Invoice: View | Pilot Planner</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Invoice Details"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Invoices',
            },
            { name: `INV-${currentInvoice?.categoryNumber}` },
          ]}
        />
      </Container>
    </>
  );
}
