import { Navigate, useRoutes } from 'react-router-dom';
import LessonsListPage from 'src/pages/dashboard/LessonsListPage';
import CourseListPage from 'src/pages/dashboard/CourseListPage';
import QuestionsListPage from 'src/pages/dashboard/QuestionsListPage';
import ExamsListPage from 'src/pages/dashboard/ExamsListPage';
import CourseDetailsPage from 'src/pages/dashboard/CourseDetailsPage';
import CourseEditPage from 'src/pages/dashboard/CourseEditPage';
import CourseCreatePage from 'src/pages/dashboard/CourseCreatePage';
// auth
import useRoleBasedAccess from 'src/hooks/useRoleBasedAccess';
import GuestGuard from '../auth/GuestGuard';
import AuthGuard from '../auth/AuthGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  // MUIAccordionPage,
  // MUIAlertPage,
  // MUIAutocompletePage,
  // MUIAvatarPage,
  // MUIBadgePage,
  // MUIBreadcrumbsPage,
  // MUIButtonsPage,
  // MUICheckboxPage,
  // MUIChipPage,
  // MUIDataGridPage,
  // MUIDialogPage,
  // MUIListPage,
  // MUIMenuPage,
  // MUIPaginationPage,
  // MUIPickersPage,
  // MUIPopoverPage,
  // MUIProgressPage,
  // MUIRadioButtonsPage,
  // MUIRatingPage,
  // MUISliderPage,
  // MUIStepperPage,
  // MUISwitchPage,
  // MUITablePage,
  // MUITabsPage,
  // MUITextFieldPage,
  // MUITimelinePage,
  // MUITooltipPage,
  // MUITransferListPage,
  // MUITreesViewPage,
  // //
  // DemoAnimatePage,
  // DemoCarouselsPage,
  // DemoChartsPage,
  // DemoCopyToClipboardPage,
  // DemoEditorPage,
  // DemoFormValidationPage,
  // DemoImagePage,
  // DemoLabelPage,
  // DemoLightboxPage,
  // DemoMapPage,
  // DemoMegaMenuPage,
  // DemoMultiLanguagePage,
  // DemoNavigationBarPage,
  // // DemoOrganizationalChartPage,
  // DemoScrollbarPage,
  // DemoSnackbarPage,
  // DemoTextMaxLinePage,
  // DemoUploadPage,
  // DemoMarkdownPage,
  CategoryListPage,
  CategoryDetailsPage,
  CategoryEditPage,
  CategoryCreatePage,
  LessonsDetailsPage,
  LessonsEditPage,
  LessonsCreatePage,
  QuestionsCreatePage,
  QuestionsDetailsPage,
  QuestionsEditPage,
  ExamsDetailsPage,
  ExamsEditPage,
  ExamsCreatePage,
  // Licences
  LicencesListPage,
  LicencesDetailsPage,
  LicencesEditPage,
  LicencesCreatePage,
  PrivacyPolicyPage,
  TermsConditionsPage,
  // Mediacal Licences
  MedicalLicencesListPage,
  MedicalLicencesDetailsPage,
  MedicalLicencesCreatePage,
  MedicalLicencesEditPage
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  
  const isAuthorized = useRoleBasedAccess(['admin']);

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    {
      path: 'privacy-policy',
      element: <PrivacyPolicyPage/>
    },
    {
      path: 'terms-of-use',
      element: <TermsConditionsPage/>
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        {
          path: 'licences',
          children: [
            { element: <Navigate to="/dashboard/licences/list" replace />, index: true },
            { path: 'list', element: <LicencesListPage /> },
            { path: ':id', element: <LicencesDetailsPage /> },
            { path: ':id/edit', element: isAuthorized ?  <LicencesEditPage/> : <PermissionDeniedPage/> },
            { path: 'new', element: isAuthorized ? <LicencesCreatePage/> :  <PermissionDeniedPage/> },
          ],
        },
        {
          path: 'medicalLicences',
          children: [
            { element: <Navigate to="/dashboard/medicalLicences/list" replace />, index: true },
            { path: 'list', element: <MedicalLicencesListPage /> },
            { path: ':id', element: <MedicalLicencesDetailsPage /> },
            { path: ':id/edit', element: <MedicalLicencesEditPage/> },
            { path: 'new', element:<MedicalLicencesCreatePage/> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: isAuthorized ? <UserCardsPage /> : <PermissionDeniedPage/> },
            { path: 'list', element: isAuthorized ? <UserListPage /> : <PermissionDeniedPage/> },
            { path: 'new', element: isAuthorized ? <UserCreatePage/> : <PermissionDeniedPage/> },
            { path: ':id/edit', element: isAuthorized ? <UserEditPage/> : <PermissionDeniedPage/> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryListPage /> },
            { path: ':id', element: <CategoryDetailsPage /> },
            { path: ':id/edit', element: isAuthorized ? <CategoryEditPage /> : <PermissionDeniedPage/>},
            { path: 'new', element: isAuthorized ? <CategoryCreatePage /> : <PermissionDeniedPage/>},
          ],
        },
        {
          path: 'lessons',
          children: [
            { element: <Navigate to="/dashboard/lessons/list" replace />, index: true },
            { path: 'list', element: <LessonsListPage /> },
            { path: ':id', element: <LessonsDetailsPage /> },
            { path: ':id/edit', element: isAuthorized ? <LessonsEditPage/> : <PermissionDeniedPage/>},
            { path: 'new', element: isAuthorized ? <LessonsCreatePage/> : <PermissionDeniedPage/> },
          ],
        },
        {
          path: 'courses',
          children: [
            { element: <Navigate to="/dashboard/courses/list" replace />, index: true },
            { path: 'list', element: <CourseListPage /> },
            { path: ':id', element: <CourseDetailsPage /> },
            { path: ':id/edit', element: isAuthorized ? <CourseEditPage/> : <PermissionDeniedPage/>},
            { path: 'new', element: isAuthorized ? <CourseCreatePage/> : <PermissionDeniedPage/>},
          ],
        },
        {
          path: 'questions',
          children: [
            { element: <Navigate to="/dashboard/questions/list" replace />, index: true },
            { path: 'list', element: <QuestionsListPage /> },
            { path: ':id', element: <QuestionsDetailsPage /> },
            { path: ':id/edit', element: isAuthorized ? <QuestionsEditPage/> : <PermissionDeniedPage/>},
            { path: 'new', element: isAuthorized ? <QuestionsCreatePage/> : <PermissionDeniedPage/>},
          ],
        },
        {
          path: 'exams',
          children: [
            { element: <Navigate to="/dashboard/exams/list" replace />, index: true },
            { path: 'list', element: <ExamsListPage /> },
            { path: ':id', element: <ExamsDetailsPage /> },
            { path: ':id/edit', element: isAuthorized ? <ExamsEditPage/> : <PermissionDeniedPage/> },
            { path: 'new', element: isAuthorized ? <ExamsCreatePage/>  : <PermissionDeniedPage/> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
        {
          path: 'components',
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: 'foundation',
              children: [
                { element: <Navigate to="/components/foundation/colors" replace />, index: true },
                { path: 'colors', element: <FoundationColorsPage /> },
                { path: 'typography', element: <FoundationTypographyPage /> },
                { path: 'shadows', element: <FoundationShadowsPage /> },
                { path: 'grid', element: <FoundationGridPage /> },
                { path: 'icons', element: <FoundationIconsPage /> },
              ],
            },
            // {
            //   path: 'mui',
            //   children: [
            //     { element: <Navigate to="/components/mui/accordion" replace />, index: true },
            //     { path: 'accordion', element: <MUIAccordionPage /> },
            //     { path: 'alert', element: <MUIAlertPage /> },
            //     { path: 'autocomplete', element: <MUIAutocompletePage /> },
            //     { path: 'avatar', element: <MUIAvatarPage /> },
            //     { path: 'badge', element: <MUIBadgePage /> },
            //     { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
            //     { path: 'buttons', element: <MUIButtonsPage /> },
            //     { path: 'checkbox', element: <MUICheckboxPage /> },
            //     { path: 'chip', element: <MUIChipPage /> },
            //     { path: 'data-grid', element: <MUIDataGridPage /> },
            //     { path: 'dialog', element: <MUIDialogPage /> },
            //     { path: 'list', element: <MUIListPage /> },
            //     { path: 'menu', element: <MUIMenuPage /> },
            //     { path: 'pagination', element: <MUIPaginationPage /> },
            //     { path: 'pickers', element: <MUIPickersPage /> },
            //     { path: 'popover', element: <MUIPopoverPage /> },
            //     { path: 'progress', element: <MUIProgressPage /> },
            //     { path: 'radio-button', element: <MUIRadioButtonsPage /> },
            //     { path: 'rating', element: <MUIRatingPage /> },
            //     { path: 'slider', element: <MUISliderPage /> },
            //     { path: 'stepper', element: <MUIStepperPage /> },
            //     { path: 'switch', element: <MUISwitchPage /> },
            //     { path: 'table', element: <MUITablePage /> },
            //     { path: 'tabs', element: <MUITabsPage /> },
            //     { path: 'textfield', element: <MUITextFieldPage /> },
            //     { path: 'timeline', element: <MUITimelinePage /> },
            //     { path: 'tooltip', element: <MUITooltipPage /> },
            //     { path: 'transfer-list', element: <MUITransferListPage /> },
            //     { path: 'tree-view', element: <MUITreesViewPage /> },
            //   ],
            // },
            // {
            //   path: 'extra',
            //   children: [
            //     { element: <Navigate to="/components/extra/animate" replace />, index: true },
            //     { path: 'animate', element: <DemoAnimatePage /> },
            //     { path: 'carousel', element: <DemoCarouselsPage /> },
            //     { path: 'chart', element: <DemoChartsPage /> },
            //     { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
            //     { path: 'editor', element: <DemoEditorPage /> },
            //     { path: 'form-validation', element: <DemoFormValidationPage /> },
            //     { path: 'image', element: <DemoImagePage /> },
            //     { path: 'label', element: <DemoLabelPage /> },
            //     { path: 'lightbox', element: <DemoLightboxPage /> },
            //     { path: 'map', element: <DemoMapPage /> },
            //     { path: 'mega-menu', element: <DemoMegaMenuPage /> },
            //     { path: 'multi-language', element: <DemoMultiLanguagePage /> },
            //     { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
            //     // { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
            //     { path: 'scroll', element: <DemoScrollbarPage /> },
            //     { path: 'snackbar', element: <DemoSnackbarPage /> },
            //     { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
            //     { path: 'upload', element: <DemoUploadPage /> },
            //     { path: 'markdown', element: <DemoMarkdownPage /> },
            //   ],
            // },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
