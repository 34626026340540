import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'src/auth/FirebaseContext';
import CourseNewEditForm from 'src/sections/@dashboard/courses/form/CourseNewEditForm';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { withId } from 'src/firestore/withId';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import { PATH_DASHBOARD } from '../../routes/paths';


export default function CourseCreatePage() {
  const { themeStretch } = useSettingsContext();
  const [lessonsItem, setLessons] = useState([]);
  const [examsItem, setExams] = useState([]);

  const [licences] = useCollectionData(withId(collection(db, 'licences')));
  const licenceOptions = licences?.map(licence => ({ value: licence?.id, label: licence?.title }));

  const fetchLessons = async () => {
    try {
      const lessons = await getDocs(collection(db, 'lessons'));
      const lessonsData = lessons.docs.map(lesson => ({ label: lesson.get('title'), value: lesson.id }));

      setLessons(lessonsData as any);
    } catch (err) {
      console.error(err);
    }
  }

  const fetchExams = async () => {
    try {
      const exams = await getDocs(collection(db, 'exams'));
      const examsData = exams.docs.map(exam => ({ label: exam.get('title'), value: exam.id }));

      setExams(examsData as any);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchLessons();
    fetchExams();
  }, [])

  return (
    <>
      <Helmet>
        <title> Courses: Create a new course | Pilot Planner</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new course"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Courses',
              href: PATH_DASHBOARD.courses.list,
            },
            {
              name: 'New Course',
            },
          ]}
        />
        <CourseNewEditForm lessons={lessonsItem} exams={examsItem} licences={licenceOptions} />
      </Container>
    </>
  );
}
